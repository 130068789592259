<template>
    <div class="col-md-12" style="height: 100%;padding-top: 60px;padding: 0px;">
        <ScannerCamera />
        <div class="scan-camera">
            <div></div>
            <div  class="scanner-overlay">
                <div class="scanner-overlay-wrapper">
                    <img class="target-overlay" src="@/assets/img/cam-overlay.png">
                    <span v-show="showMessage" class="scan-explain">{{explainScanValue}}</span>
                </div>
            </div>
            <div>
                <ProductBloc v-if="scannedProduct" :key="productKey" :product="scannedProduct" @updateQty="$parent.basketChange()"/>
            </div>
            <div class="scanner-footer">
                <FooterComponent :isOffline="isOffline" v-if="showBasket" :scannedProduct="scannedProduct" disableScanBtn/>
                <div v-if="scanLoyaltyCard" @click="toPaymentMethod" class="scan-loyalty-actions">
                    <span class="px-4 py-2 custom-btn outlined font-weight-normal">Annuler</span>
                </div>
            </div>

        </div>
        <Alert v-if="alertContent!=''" :alertContent="alertContent" @dismissed="$event == 0 ? alertContent = '' : ''" :alertVariant="'danger'"></Alert>
        <audio id="beep" ref="beep" controls hidden>
            <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
            {{$t('errorSound')}}
        </audio>
    </div>
</template>

<script>

    import {isNotNull} from "@/assets/js/Utils"
    import FooterComponent from "@/components/Food/FooterComponent";
    import ScannerCamera from "@/components/Food/ScannerCamera";
    import ProductBloc from "@/components/Food/ProductBloc";
    import { HalfCircleSpinner } from 'epic-spinners'

    import Alert from "@/components/Common/Alert";
    var Search = require("@/assets/js/SearchProduct");
    var Barcode = require("@/assets/js/Barcode");
    var Basket = require("@/assets/js/Food/Basket");
    var MQTT = require("@/assets/js/Food/MqttService");
    var App = require("@/assets/js/App");
    var Stats = require("@/assets/js/Stats");

    export default {
        name: "Scan",
        components: {ProductBloc, Alert, FooterComponent, HalfCircleSpinner, ScannerCamera},
        props: ['isOffline'],   
        data() {
            return {
                ean: '',
                scanned_products: [],
                products: [],
                timers: {
                    productsTimer: null,
                    scannedProductsTimer: null,
                    errorTimer: null
                },
                scanType: "QRCODE",
                inPayment: this.$route.query.inPayment,
                needParameters: this.$route.query.needParameters,
                scanLoyaltyCard: this.$route.query.scanLoyaltyCard,
                alertContent: "",
                explainScanValue: this.$t('messageScanExplain'),
                explainCartValue: this.$t('messageCartExplain'),
                loaded: false,
                scannedProduct: null,
                addingLoader: false,
                productKey: this.generateKey(),
                showMessage: true
            }
        },
        mounted() {
            var showQRScanner = false;
            if(isNotNull(this.inPayment)){
                this.explainScanValue = this.$t('messageScanExplainPayment');
                showQRScanner = true;
            }else if(isNotNull(this.needParameters)){
                this.explainScanValue = this.$t('messageScanExplainParameters');
                showQRScanner = true;
            }
            var needQRCodeScanner = false;
            var needBarCodeScanner = false;
            if(isNotNull(this.scanLoyaltyCard) && this.scanLoyaltyCard == "true"){
                needBarCodeScanner = true;
            }
            if((isNotNull(this.inPayment) && this.inPayment == "true") || (isNotNull(this.needParameters) && this.needParameters == "true")){
                needQRCodeScanner = true;
            }
            Barcode.start(this.onDecode);

            var scanType = localStorage.getItem("DKC_scanType");
            if ((needBarCodeScanner && !needQRCodeScanner) || (!showQRScanner && isNotNull(scanType) && scanType == "BARCODE")) {
                this.scanType = "BARCODE";
            }else{
                this.scanType = "QRCODE";
            }

            if(isNotNull(this.scanCabin)){
                let self = this;
                this.$parent.showLoader = true;
                setTimeout(function(){
                    self.$parent.showLoader = false;
                    //self.$refs.bagCartCamera.style.display = "none";
                    //self.$refs.bagScanCamera.style.display = "block";
                    self.explainScanValue = self.$t('messageCartExplainCabinScan');
                }, 1000)
            }

            if(isNotNull(this.scanLoyaltyCard)){
                this.explainScanValue = this.$t('messageCartExplainLoyaltyScan');
            }
        },
        methods: {
            onDecode(decodedString) {
                this.$refs.beep.play();
                if(isNotNull(this.inPayment)){
                    this.onPaymentScanned(decodedString);
                }else if(isNotNull(this.needParameters)){
                    this.onParametersScanned(decodedString);
                }else if(isNotNull(this.scanLoyaltyCard)){
                    this.onLoyaltyCardScanned(decodedString);
                }else{
                    this.onProductScanned(decodedString);
                }
            },
            onPaymentScanned(decodedString){
                var laneKey = "payment_DKS:";
                var laneKeyPaperSupport = "payment_paper_support:";
                try{
                    if(decodedString.codeResult.code.includes(laneKey)){
                        var terminalId = decodedString.codeResult.code.substring(laneKey.length);
                        if(this.$parent.basket.totalAmount > 0) {
                            this.$parent.showLoader = true;
                            var loyaltyToUse = localStorage.getItem("DKC_loyaltyToUse");
                            MQTT.publishMessage("SCAN_LANE", '{"basketPrice": ' + (this.$parent.basket.totalAmount - loyaltyToUse) + ', "terminalId": ' + terminalId + '}');
                            MQTT.susbscribeLaneSuccess(this);
                        }
                    }else if(decodedString.codeResult.code.includes(laneKeyPaperSupport)){
                        this.$parent.showLoader = true;
                        setTimeout(()=>{
                            this.$parent.showLoader = false;
                            this.$router.push(localStorage.getItem("DKC_base") + "/ticket");
                        }, 5000)
                    }else{
                        this.alertContent = this.$t('scanErrorPayment');
                    }
                }catch(e){
                    console.error("Scanner Error", e)
                }
            },
            onParametersScanned(decodedString){
                var controlKey = "/p=";
                if(decodedString.includes(controlKey)){
                    var pathname = decodedString.substring(window.location.origin.length);
                    App.checkParameters(pathname, this);
                    this.$parent.setBase();
                    localStorage.setItem("DKC_needPublishPoster", "true");
                    window.location.replace(window.location.origin + localStorage.getItem("DKC_base") + '/');
                }else{
                    this.alertContent = this.$t('scanErrorParameters');
                }
            },
            onLoyaltyCardScanned(decodedString){
                localStorage.setItem("DKC_loyaltyCard", decodedString.codeResult.code);
                this.$router.push(localStorage.getItem("DKC_base") + '/paymentMethods');
            },
            onProductScanned(decodedString){
                this.addingLoader = true
                let self = this;
                let tmpList = [];
                if (decodedString.codeResult) {
                    this.ean = decodedString.codeResult.code;
                } else {
                    this.ean = decodedString.substring(decodedString.lastIndexOf('=') + 1);
                }
                //this.ean = "3228881011138";

                if (true) {
                    this.scanned_products.push(this.ean);
                    Search.getProductByEan(this.ean.split("_")[0], function(product) {
                        if (!product.error) {
                            clearTimeout(self.timers.productsTimer);
                            tmpList.push(product);
                            product.ean = self.ean;
                            self.products = tmpList;
                            self.scannedProduct = product;
                            self.addingLoader = false;
                            self.productKey = self.generateKey()
                            self.showMessage = false

                            if(isNotNull(product.productCrossSell)){
                                localStorage.setItem("DKC_productCrossSell", JSON.stringify(product.productCrossSell));
                                self.$parent.productCrossSell = product.productCrossSell;
                            }
                            
                            if(self.$parent.nbProductsCart < 20){
                                Basket.addProductToLocalBasket(product);
                                let scannedProduct = {
                                    productCode : product.descriptionFr
                                }
                                self.timers.productsTimer = setTimeout(()=> {
                                    self.scannedProduct = null;
                                }, 6000)
                                
                                MQTT.publishMessage("SCAN_PRODUCT", JSON.stringify(scannedProduct));
                                self.$parent.basket = Basket.getBasket();
                                self.$parent.basketChange();
                        
                                Stats.setStats({nbProductsScanned: 1});
                            }
                        }
                    });
                }
            },
            onSubmit(evt) {
                evt.preventDefault();
            },
            existInBasket(ean) {
                var exist = false;
                this.$parent.basket.lockedProducts.forEach(function(lockedProduct) {
                    if (lockedProduct == ean) {
                        exist = true;
                    }
                })
                return exist;
            },
            generateKey(){
                return 'scannedProduct-'+ parseInt(Math.random() *1000)
            },
            toPaymentMethod(){
                Basket.updateBasket(newBasket => {
                    localStorage.setItem("DKC_basket", JSON.stringify(newBasket));
                    this.$emit('basketChgt');
                    this.$router.push(localStorage.getItem("DKC_base") + '/paymentMethods');
                })
            },
            simulateScan(ean){
                this.onProductScanned(ean)
            }
        },
        computed:{
            showBasket(){
                return !this.needParameters && !this.inPayment && !this.scanLoyaltyCard
            }
        },
        beforeRouteLeave(to, from, next) {
            /* eslint-disable */
            Barcode.reset()
            next();
        }
    }
</script>